import { Image, Link, RawHtml, Text } from '~/shared/components';

import { useTheme } from '@emotion/react';
import React, { useRef } from 'react';
import { M10HeroModule } from '~/lib/data-contract';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { AlignContent } from '../AlignContent';
import { ModuleContainer } from '../ModuleContainer';
import { StyledM10, StyledM10Content, StyledHeroContent, StyledMediaWrapper } from './styled';
import { queries } from '~/theme/breakpoints';
import { Button } from '~/shared/components/Button/Button';
import { useIntersection } from 'react-use';
import dynamic from 'next/dynamic';

const Video = dynamic(() => import('~/shared/components/Video/Video'));

export type M10HeroProps = M10HeroModule;
export const M10Hero = ({
    callToAction,
    height,
    backgroundColor: initialBackgroundColor,
    textColor,
    alternativeBackgroundColor,
    alternativeTextColor,
    mediaLayout = 'split',
    ...rest
}: M10HeroProps) => {
    const { backgroundColor } = useModuleTheme({
        backgroundColor: initialBackgroundColor,
    });

    return (
        <ModuleContainer fullWidth {...rest}>
            <StyledM10 height={height} backgroundColor={backgroundColor}>
                <StyledM10ContentElement
                    mediaLayout={mediaLayout}
                    callToAction={callToAction}
                    height={height}
                    textColor={textColor}
                    alternativeBackgroundColor={alternativeBackgroundColor}
                    alternativeTextColor={alternativeTextColor}
                    {...rest}
                />
            </StyledM10>
        </ModuleContainer>
    );
};

export default M10Hero;

interface CTAWrapperProps {
    active: boolean;
    cta?: M10HeroProps['callToAction'];
    textColor?: M10HeroProps['textColor'];
    children: React.ReactNode;
}

const CTAWrapper: React.FC<CTAWrapperProps> = ({ active, cta, textColor, children }) => {
    if (active && cta) {
        return (
            <Link noHover href={cta.url} color={textColor}>
                {children}
            </Link>
        );
    }
    return <>{children}</>;
};

const StyledM10ContentElement = ({
    image,
    imageAltText,
    video,
    headline,
    priority,
    subHeadline,
    subSubHeadline,
    color,
    callToAction,
    description,
    height,
    mediaLayout = 'full',
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    verticalAlignment = 'center',
    textColor: initialTextColor,
    alternativeBackgroundColor,
    alternativeTextColor,
}: M10HeroProps) => {
    const { sizes } = useTheme();

    const ref = useRef<HTMLDivElement>(null);
    const shouldLoad = useIntersection(ref, {
        root: null,
        rootMargin: mediaLayout === 'full' && video?.mute ? '1200px' : '0px',
    });

    const { textColor } = useModuleTheme({
        textColor: initialTextColor,
    });

    const mediaType = video ? 'video' : 'image';

    const imageSizes = {
        full: `${queries.xs} 100vw, ${queries.pageMax} 100vw, ${sizes.pageMaxWidth}px`,
        split: `${queries.xs} 100vw, ${queries.pageMax} 50vw, ${sizes.pageMaxWidth / 2}px`,
    };

    const allowVideoCTA =
        !!callToAction && mediaLayout != 'split' && (video ? video.autoPlay || !video.mute : true);

    return (
        <StyledM10Content
            mediaSize={mediaLayout}
            contentAlignment={horizontalAlignment}
            mediaAlignment={mediaHorizontalAlignment}
            height={height}
            ref={ref}
        >
            <AlignContent alignment={horizontalAlignment} position={verticalAlignment}>
                <StyledHeroContent>
                    <CTAWrapper active={!!callToAction} cta={callToAction} textColor={textColor}>
                        {headline && (
                            <Text
                                children={headline.text}
                                variant={headline.style}
                                as={headline.tag}
                                color={headline.textColor || textColor}
                            />
                        )}
                        {subHeadline && (
                            <Text
                                css={{ marginTop: headline ? 16 : 0 }}
                                children={subHeadline?.text}
                                as={subHeadline?.tag}
                                variant={subHeadline.style}
                                color={subHeadline.textColor || textColor}
                            />
                        )}
                        {subSubHeadline && (
                            <Text
                                css={{ marginTop: subHeadline ? 16 : 0 }}
                                children={subSubHeadline?.text}
                                as={subSubHeadline?.tag}
                                variant={subSubHeadline.style}
                                color={subSubHeadline.textColor || textColor}
                            />
                        )}
                        {description && (
                            <RawHtml
                                css={{ marginTop: subSubHeadline ? 16 : 0 }}
                                html={description}
                            />
                        )}
                        {callToAction && mediaLayout == 'split' && (
                            <Button
                                css={{ marginTop: description ? 0 : 24 }}
                                variant={color ?? 'primary'}
                                children={callToAction.text}
                                alternativeBackgroundColor={alternativeBackgroundColor}
                                alternativeTextColor={alternativeTextColor}
                            />
                        )}
                    </CTAWrapper>
                </StyledHeroContent>

                {mediaType === 'image' && image && (
                    <CTAWrapper
                        active={!!callToAction} // No CTA if muted and no autoplay
                        cta={callToAction}
                        textColor={textColor}
                    >
                        <StyledMediaWrapper>
                            <Image
                                src={image.src}
                                alt={image.alt || imageAltText || image.name}
                                layout="fill"
                                objectFit="cover"
                                sizes={imageSizes[mediaLayout]}
                                priority={priority}
                                focalPoint={image.focalPoint}
                            />
                        </StyledMediaWrapper>
                    </CTAWrapper>
                )}

                {mediaType === 'video' && video && (!!shouldLoad?.isIntersecting ?? priority) && (
                    <CTAWrapper
                        active={allowVideoCTA} // No CTA if muted and no autoplay
                        cta={callToAction}
                        textColor={textColor}
                    >
                        <StyledMediaWrapper>
                            <Video
                                muted={video.mute}
                                controls={false}
                                src={video.src}
                                loop={video.loop}
                                playing={video.autoPlay}
                                posterSrc={image?.src}
                                cover={true}
                                theaterMode={video.theaterMode}
                            />
                        </StyledMediaWrapper>
                    </CTAWrapper>
                )}
            </AlignContent>
        </StyledM10Content>
    );
};
